import React from 'react'
import PropTypes from 'prop-types'
import { FooterImage, AboutImage } from './styles'
import { Description, LinkRed, PostContainer } from '../Styled'

const About = ({ image, isFooter = false }) => (
  <PostContainer>
    {isFooter && (
      <>
        <hr />
        <FooterImage />
        <Description>Hi, thanks for reading! I&apos;m David.</Description>
      </>
    )}{' '}
    {!isFooter && <AboutImage fluid={image} />}
    <Description>
      Welcome to my blog!
      <br />
      <br />
      I&apos;m a
    </Description>{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.instagram.com/tuxedosnob/">
      <LinkRed>classical menswear enthusiast</LinkRed>
    </a>{' '}
    <Description>
      living in Prague, Czech Republic. Other than that, I&apos;m also a lawyer
      turned engineer, and occasional conference speaker.
      <br />
      I created this blog to share my opinions and knowledge about
      gentlemen&apos;s classic style with all of you. Hopefully, you&apos;ll
      find information presented on this blog useful and learn a thing or two
      about classic menswear you didn&apos;t know before!
      <br />
      Who knows, perhaps you&apos;ll even start to think about the clothes you
      wear differently and maybe even change your wardrobe and your personal
      style in the end.
    </Description>
    <Description>
      <br />
      <br />
      Yours,
      <br />
      David K.
    </Description>
  </PostContainer>
)

About.propTypes = {
  image: PropTypes.object,
  isFooter: PropTypes.bool,
}

About.defaultProps = {
  isFooter: false,
}

export default About

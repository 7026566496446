import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Header, Container } from '../components/Styled'
import About from '../components/Home/About'
import Layout from '../layouts'
import MainHeader from '../components/MainHeader'

const AboutTemplate = ({ data }) => (
  <Layout title="About me" isPost>
    <MainHeader />
    <Container>
      <Header>About</Header>
      <About image={data.file.childImageSharp.fluid} />
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "david.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

AboutTemplate.propTypes = {
  data: PropTypes.object,
}

export default AboutTemplate

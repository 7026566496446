import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import mq from '../../utils/styling'
import profilePhoto from '../../images/david.png'

export const AboutImage = styled(Img)`
  height: 100px;
  padding-top: 6px;
  width: 100px;
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto 15px auto;
  ${mq.medium(css`
    height: 150px;
    width: 150px;
    display: flex !important;
  `)};
  ${mq.large(css`
    margin: 0 auto 40px auto;
    height: 200px;
    width: 200px;
    display: flex !important;
  `)};
`

export const FooterImage = styled.div`
  background-image: url(${profilePhoto});
  background-size: cover;
  border-radius: 50%;
  float: right;
  margin: 0 12px 0 -112;
  height: 100px;
  width: 100px;
  ${mq.large(css`
    float: left;
    margin-left: -112px;
  `)};
`
